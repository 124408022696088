import { Admin, Layout, Sidebar, buildI18nProvider } from "@react-admin/ra-enterprise";
import AppBar from "./AppBar";
import {
  AppLocationContext
} from "@react-admin/ra-navigation";

import { Resource } from "@react-admin/ra-rbac";
import Dashboard from "./Dashboard";
import authProvider from "./authProvider";
import UserList from "./UserList";
import LockLists from "./LockLists";
import UnlockLists from "./UnlockLists";
import DepositList from "./DepositList";
import DepositBitcoinList from "./DepositBitcoinList";
import DepositEthereumList from "./DepositEthereumList";
import DepositUsdterc20List from "./DepositUsdterc20List";
import DepositUsdttrc20List from "./DepositUsdttrc20List";
import DepositUsdcerc20List from "./DepositUsdcerc20List";
import DepositUsdctrc20List from "./DepositUsdctrc20List";
import DepositPMUSD from "./DepositPMUSD";
import DepositFPUSD from "./DepositFPUSD";
import DepositAdvcusd from "./DepositAdvcusd";
import DepositPayeer from "./DepositPayeer";
import WithdrawList from "./WithdrawList";
import BlockLists from "./BlockLists";
import EditUser from "./UserEdit";
import OrderList from "./OrderLists";
import ReferralList from "./ReferralList";
import lightTheme from "./Theme";
import darkTheme from "./ThemeDark";
import MyMenu from "./Menu";
import OrderDetails from "./OrderDetails";
import BaseCurrency from "./BaseCurrency";
import CryptoCurrency from "./CryptoCurrency";
import Ecurrencies from "./Ecurrencies";
import Fiatcurrencies from "./Fiatcurrencies";
import Brokerage from "./Brokerage";
import PriceStore from "./PriceStore";
import Sellrate from "./Sellrate";
import Buyrate from "./Buyrate";
import ExchangePair from "./ExchangePair";
import BuyPair from "./BuyPair";
import SellPair from "./SellPair";
import EditBuyPair from "./EditBuyPair";
import EditSellPair from "./EditSellPair";
import EarnCommission from "./EarnCommission";
import ReferralCommission from "./ReferralCommission";
import CanceledCommission from "./CanceledCommission";
import CompletedCommission from "./CompletedCommission";
import PendingCommission from "./PendingCommission";
import CanceledWithdrawDetails from "./CanceledWithdrawDetails";
import PendingWithdrawDetails from "./PendingWithdrawDetails";
import CompletedWithdrawDetails from "./CompletedWithdrawDetails";
import WithdrawFees from "./WithdrawFees";
import TermsOfUse from "./TermsOfUse";
import PrivacyPolicy from "./PrivacyPolicy";
import AmlPolicy from "./AmlPolicy";
import MediaManagement from "./MediaManagement";
import EditMedia from "./EditMedia";
import NewsManagement from "./NewsManagement";
import EditNews from "./EditNews";
import Campaign from "./Campaign";
import AddMedia from "./AddMedia";
import AddNews from "./AddNews";
import dataProvider from "./dataProvider";
import RoleBaseSettingEditor from "./RoleBaseSettingEditor";
import CountrySetting from "./CountrySettings";
import IpWhiteList from "./IpWhiteList";
import RoleBaseSettingAccountant from "./RoleBaseSettingAccountant";
import RoleBaseSettingAuditor from "./RoleBaseSettingAuditor";
import englishMessages from "./langlib/en";
import MyEventList from "./EventList";
import { useState, useEffect } from "react";
import JWT from "jsonwebtoken";
import api_url from "./ApiUrl";
import { Typography } from "@mui/material";
import ProgressBar from "./progress";
import { light } from "@material-ui/core/styles/createPalette";

const CustomLayout = (props) => {
  return (
    <AppLocationContext>
      <Layout {...props} appBar={AppBar} menu={MyMenu} />
    </AppLocationContext>
  );
};

const messages = {
  en: englishMessages,
};
const i18nProvider = buildI18nProvider(messages, 'en');

function idRoleAllow(resource_name, action_name) {
  let return_value = 'no';
  let login_role = localStorage.getItem('role');
  if (login_role == 'admin') {
    return 'ok';
  }
  let allpermission = JSON.parse(localStorage.getItem("roles"));
  let roledata = [];
  if (login_role == 'accountant') {
    roledata = allpermission.accountant;
  }
  if (login_role == 'auditor') {
    roledata = allpermission.auditor;
  }
  if (login_role == 'editor') {
    roledata = allpermission.editor;
  }
  roledata.map((role_row, index) => {
    if ((role_row.resource == resource_name) && (role_row.action.indexOf(action_name) > -1)) {
      return_value = 'ok';
    }
  });
  return return_value;
}

const App = () => {
  const [bdata, setBdata] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const getCountryLogWithFetch = async () => {
    setIsLoading(true);
    let login_token = localStorage.getItem("auth");
    let apitoken = JWT.sign({ url: api_url() }, "eyJhbGciOJIUzI1NiIsInR5cCI6IkpXVCJ9eyJzdWIiOiIxMjM0NTY3ODkwIiwibmFtZSI6IkpvaG4gRG9lIiwiaWF0IjoxNTE2Mj5MDIyfQSflKxwRJSMeKF2QT4fwpMeJf36POk6yJVadQsw5c", {
      algorithm: "HS512",
    });
    const request = new Request(api_url() + "/siteavailble", {
      method: "GET",
      headers: new Headers({
        "Content-Type": "application/json",
        Authorization: `Bearer ${login_token}`,
        'xelxtoken': apitoken
      }),
    });
    const response = await fetch(request);
    const resourceJsonData = await response.json();
    setBdata(resourceJsonData.c_lock);
    setIsLoading(false);
  };

  useEffect(() => {
    getCountryLogWithFetch();
  }, []);

  window.addEventListener("beforeunload", (event) => {
    getCountryLogWithFetch();
  });

  window.addEventListener("unload", (event) => {
    getCountryLogWithFetch();
  });

  const allComponents = (<Admin
    authProvider={authProvider}
    dataProvider={dataProvider}
    layout={CustomLayout}
    i18nProvider={i18nProvider}
    lightTheme={lightTheme}
    defaultTheme={light}
    title=""
  >
    <Resource name="dashboard" list={Dashboard} />
    <Resource name="users" list={UserList} edit={EditUser} />
    <Resource name="locked" list={LockLists} />
    <Resource name="unlocked" list={UnlockLists} />
    <Resource name="blocked" list={BlockLists} />
    <Resource name="rolesettingeditor" list={RoleBaseSettingEditor} />
    <Resource name="countrysettings" list={CountrySetting} />
    <Resource name="ipwhitelist" list={IpWhiteList} />
    <Resource name="rolesettingaccountant" list={RoleBaseSettingAccountant} />
    <Resource name="rolesettingauditor" list={RoleBaseSettingAuditor} />
    <Resource name="orders" list={OrderList} show={OrderDetails} />
    <Resource name="referralinfos" list={ReferralList}/>
    <Resource name="deposits" list={DepositList} />
    <Resource name="depositbitcoin" list={DepositBitcoinList} />
    <Resource name="depositethereum" list={DepositEthereumList} />
    <Resource name="depositusdterc20" list={DepositUsdterc20List} />
    <Resource name="depositusdttrc20" list={DepositUsdttrc20List} />
    <Resource name="depositusdcerc20" list={DepositUsdcerc20List} />
    <Resource name="depositusdctrc20" list={DepositUsdctrc20List} />
    <Resource name="depositpmusd" list={DepositPMUSD} />
    <Resource name="depositfpusd" list={DepositFPUSD} />
    <Resource name="depositadvcusd" list={DepositAdvcusd} />
    <Resource name="depositpayeer" list={DepositPayeer} />
    <Resource name="withdraws" list={WithdrawList} />
    <Resource name="basecurrency" list={BaseCurrency} />
    <Resource name="cryptocurrency" list={CryptoCurrency} />
    <Resource name="ecurrencies" list={Ecurrencies} />
    <Resource name="fiatcurrencies" list={Fiatcurrencies} />
    <Resource name="brokerage" list={Brokerage} />
    <Resource name="pricestore" list={PriceStore} />
    <Resource name="sellrate" list={Sellrate} />
    <Resource name="buyrate" list={Buyrate} />
    <Resource
      name="exchangepair"
      list={ExchangePair}
    />
    <Resource
      name="buypair"
      list={BuyPair}
    />
    <Resource
      name="sellpair"
      list={SellPair}
    />
    <Resource name="earncommission" list={EarnCommission} />
    <Resource
      name="pendingcommission"
      list={PendingCommission}
      show={PendingWithdrawDetails}
    />
    <Resource
      name="canceledcommission"
      list={CanceledCommission}
      show={CanceledWithdrawDetails}
    />
    <Resource
      name="completedcommission"
      list={CompletedCommission}
      show={CompletedWithdrawDetails}
    />
    <Resource name="referralcommission" list={ReferralCommission} />
    <Resource name="withdrawfees" list={WithdrawFees} />
    <Resource name="termsofuse" list={TermsOfUse} />
    <Resource name="privacypolicy" list={PrivacyPolicy} />
    <Resource name="amlpolicy" list={AmlPolicy} />
    <Resource
      name="media"
      list={MediaManagement}
      edit={EditMedia}
      create={AddMedia}
    />
    <Resource
      name="news"
      list={NewsManagement}
      edit={EditNews}
      create={AddNews}
    />
    <Resource
      name="campaign"
      list={Campaign}
      create={Campaign}
    />
    <Resource name="events" list={MyEventList} />
  </Admin>);
  const retrictMsg = (<Typography>The resource you are looking for has been removed, had its name changed, or is temporarily unavailable</Typography>);

  return (
    isLoading ? <ProgressBar /> : ((bdata === 'no') ? allComponents : retrictMsg)
  )
};

export default App;
