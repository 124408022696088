import api_url from "./ApiUrl";
import JWT from "jsonwebtoken";
import OtpVerify from "./OtpVerify";
//let roles = '';

let roles_data = []; // memory cache
let rolesExpiresAt = 0;

export default {
  // called when the user attempts to log in
  login: ({ username, password }) => {
    let apitoken = JWT.sign({ url: api_url() }, "eyJhbGciOJIUzI1NiIsInR5cCI6IkpXVCJ9eyJzdWIiOiIxMjM0NTY3ODkwIiwibmFtZSI6IkpvaG4gRG9lIiwiaWF0IjoxNTE2Mj5MDIyfQSflKxwRJSMeKF2QT4fwpMeJf36POk6yJVadQsw5c", {
      algorithm: "HS512",
    });
    const request = new Request(api_url() + "/login", {
      method: "POST",
      body: JSON.stringify({ username, password }),
      headers: new Headers({ "Content-Type": "application/json", 'xelxtoken': apitoken }),
    });
    return fetch(request)
      .then((response) => {
        if (response.status < 200 || response.status >= 300) {
          throw new Error(response.statusText);
        }
        return response.json();
      })
      .then((auth) => {
        if (!auth.success) {
          throw new Error(auth.message);
        }
        if (auth.success && auth.login_verify == 'no') {
          localStorage.setItem("username", username);
          localStorage.setItem("loginname", auth.login_name);
          localStorage.setItem("auth", auth.login_token);
          localStorage.setItem("access_key", auth.access_key);
          localStorage.setItem("role", auth.role);
          localStorage.setItem("roles", JSON.stringify(auth.roles));
          roles_data = auth.roles;
        } else {
          //OtpVerify();
          window.location = '/verifyOTP';
        }
      })
      .catch((error) => {
        throw new Error(error);
      });
  },
  // called when the user clicks on the logout button
  logout: () => {
    localStorage.removeItem("username");
    localStorage.removeItem("auth");
    localStorage.clear();
    return Promise.resolve();
  },
  getIdentity: () => {
    const role = localStorage.getItem("role");
    return Promise.resolve({
      id: role,
      fullName: {
        accountant: "Accountant",
        editor: "Editor",
        user: "User",
        admin: "Admin",
        auditor: "Auditor",
      }[role],
    });
  },
  // called when the API returns an error
  checkError: ({ status }) => {
    if (status === 401 || status === 403) {
      localStorage.removeItem("username");
      localStorage.removeItem("auth");
      return Promise.reject();
    }
    return Promise.resolve();
  },
  // called when the user navigates to a new location, to check for authentication
  checkAuth: () => {
    return localStorage.getItem("auth") ? Promise.resolve() : Promise.reject();
  },
  // called when the user navigates to a new location, to check for permissions / roles
  getPermissions: () => {
    const role = localStorage.getItem("role");
    return Promise.resolve({ roles: [role] });
    //return Date.now() > permissionsExpiresAt ? getPermissions() : permissions;
  },
  getRoles: () => Promise.resolve(JSON.parse(localStorage.getItem("roles"))),
  /*getRoles: () => {
    console.log(localStorage.getItem('roles').editor);
    //return Date.now() > rolesExpiresAt ? getRoles() : roles;
  },*/
};
