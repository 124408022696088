import React, { FC } from 'react';
import Switch from "react-switch";
import JWT from "jsonwebtoken";
import {
    NumberField,
    Record as RaRecord,
    Link
} from 'react-admin';
import {
    Typography,
    Card,
    CardContent,
    Box,
    InputLabel,
    Checkbox,
    Grid,
    TextField,
} from '@material-ui/core';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import DeviceHubOutlinedIcon from '@material-ui/icons/DeviceHubOutlined';
import { makeStyles } from '@material-ui/core/styles';
import api_url from './ApiUrl';
import AdjustIcon from '@material-ui/icons/Adjust';
import AccountBalanceWalletOutlinedIcon from '@material-ui/icons/AccountBalanceWalletOutlined';
import AllInclusiveOutlinedIcon from '@material-ui/icons/AllInclusiveOutlined';
import InsertChartOutlinedTwoToneIcon from '@material-ui/icons/InsertChartOutlinedTwoTone';
import OpenInBrowserOutlinedIcon from '@material-ui/icons/OpenInBrowserOutlined';

const useStyles = makeStyles((theme) => ({
    ownfont14: { fontSize: '14px', paddingBottom: '7px' },
    ownfont12: { fontSize: '12px' },
    reactSwitch: { pointerEvents: 'none' },
    paddingTop: { paddingTop: '6px !important' },
    fontWeightSize: { fontWeight: 400, fontSize: '0.875rem' },
    fontWeightSizeSub: { fontWeight: 400, fontSize: '0.75rem' },
    fontWeightSizeSub2: { fontWeight: 400, fontSize: '0.75rem', paddingTop: '7px' },
    siteColor: { color: 'rgb(38 147 230)' },
    grayColor: { color: 'rgb(128,128,128)' },
    marginTop: { marginTop: '15px' },
    fweight500: { fontWeight: 500 },
    iconColor: { color: (theme.palette.type === 'light') ? 'rgb(128,128,128)' : '#fff' }
}));


const Aside = (props) => {
    const classes = useStyles();
    return (
        <div style={{ width: '600px' }}>
            <Box m="0 0 1em 1em">
                <Card>
                    <CardContent style={{ paddingBottom: '10px' }}>
                        <Typography variant="h6" gutterBottom className={classes.fweight500}>History</Typography>
                        <Box display="flex">
                            <Box flexGrow={1}>
                                <Box display="flex" mb="1em">
                                    <Box mr="0.5em">
                                        <AccessTimeIcon fontSize="small" className={classes.iconColor} />
                                    </Box>
                                    <Box flexGrow={1}>
                                        <Typography fontSize="small" className={classes.fontWeightSize}>First seen</Typography>
                                        <InputLabel className={classes.fontWeightSizeSub2} variant="h6">{(props.record) ? props.record.created_at_alter : ''}</InputLabel>
                                        {/* <DateField variant='h6' className={classes.fontWeightSizeSub2} source="created_at" showTime /> */}
                                    </Box>
                                </Box>
                            </Box>

                            <Box flexGrow={1}>
                                <Box display="flex" mb="1em">
                                    <Box mr="0.5em">
                                        <AccessTimeIcon fontSize="small" className={classes.iconColor} />
                                    </Box>
                                    <Box flexGrow={1}>
                                        <Typography className={classes.fontWeightSize}>Last seen</Typography>
                                        <InputLabel className={classes.fontWeightSizeSub2} variant="h6">{(props.record) ? props.record.last_login_alter : ''}</InputLabel>
                                        {/* <DateField className={classes.fontWeightSizeSub2} source="last_login" showTime /> */}
                                    </Box>
                                </Box>
                            </Box>
                        </Box>

                        <Box display="flex">
                            {(props.record && props.record.total_orders > 0) ?
                                <Box flexGrow={2}>
                                    <Box display="flex">
                                        <Box mr="0.5em">
                                            <AttachMoneyIcon fontSize="small" className={classes.iconColor} />
                                        </Box>
                                        <Box flexGrow={1} className={classes.fontWeightSize}>
                                            <NumberField fontSize="small" source="total_orders" /> Orders
                                        </Box>
                                    </Box>
                                </Box> : ''}

                            {(props.record && props.record.total_affiliate_members > 0) ?
                                <Box flexGrow={2}>
                                    <Box display="flex">
                                        <Box mr="0.5em">
                                            <DeviceHubOutlinedIcon fontSize="small" className={classes.iconColor} />
                                        </Box>
                                        <Box flexGrow={1} className={classes.fontWeightSize}>
                                            <Link to={`/referralinfos?filter={"id":${props.record.id}}`} ><NumberField fontSize="small" source="total_affiliate_members" /> Referrals</Link>
                                            {/* <NumberField fontSize="small" source="total_affiliate_members" /> Referrals */}
                                        </Box>
                                    </Box>
                                </Box> : ''}

                        </Box>
                    </CardContent>
                </Card>
            </Box>


            <Box m="0 0 1em 1em">
                <Card>
                    <CardContent style={{ paddingBottom: '10px' }}>
                        <Typography variant="h6" gutterBottom className={classes.fweight500}>Referral Earning</Typography>
                        <Box display="flex" className={classes.marginTop}>
                            <Grid container>
                                <Grid item xs={6}>
                                    <Box flexGrow={1}>
                                        <Box display="flex" mb="1em">
                                            <Box mr="0.5em">
                                                <InsertChartOutlinedTwoToneIcon fontSize="small" className={classes.iconColor} />
                                            </Box>

                                            <Box flexGrow={1}>
                                                <Typography fontSize="small" className={classes.fontWeightSize}>Today's earnings</Typography>
                                                <InputLabel className={classes.fontWeightSizeSub2} variant="h6">{(props.record) ? props.record.today_income : '$0.00'}</InputLabel>
                                            </Box>
                                        </Box>
                                    </Box>
                                </Grid>
                                <Grid item xs={6}>
                                    <Box flexGrow={1}>
                                        <Box display="flex" mb="1em">
                                            <Box mr="0.5em">
                                                <AllInclusiveOutlinedIcon fontSize="small" className={classes.iconColor} />
                                            </Box>
                                            <Box flexGrow={1}>
                                                <Typography className={classes.fontWeightSize}>Total earnings</Typography>
                                                <InputLabel className={classes.fontWeightSizeSub2} variant="h6">{(props.record) ? props.record.total_income : '$0.00'}</InputLabel>
                                            </Box>
                                        </Box>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>

                        <Box display="flex">
                            <Grid container>
                                <Grid item xs={6}>
                                    <Box flexGrow={1}>
                                        <Box display="flex" mb="1em">
                                            <Box mr="0.5em">
                                                <OpenInBrowserOutlinedIcon fontSize="small" className={classes.iconColor} />
                                            </Box>
                                            <Box flexGrow={1}>
                                                <Typography fontSize="small" className={classes.fontWeightSize}>Total withdraw</Typography>
                                                <InputLabel className={classes.fontWeightSizeSub2} variant="h6">{(props.record) ? props.record.total_withdraw : '$0.00'}</InputLabel>
                                            </Box>
                                        </Box>
                                    </Box>
                                </Grid>
                                <Grid item xs={6}>
                                    <Box flexGrow={1}>
                                        <Box display="flex" mb="1em">
                                            <Box mr="0.5em">
                                                <AccountBalanceWalletOutlinedIcon fontSize="small" className={classes.iconColor} />
                                            </Box>
                                            <Box flexGrow={1}>
                                                <Typography className={classes.fontWeightSize}>Available fund</Typography>
                                                <InputLabel className={classes.fontWeightSizeSub2} variant="h6">{(props.record) ? props.record.total_available_balance : '$0.00'}</InputLabel>
                                            </Box>
                                        </Box>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>


                    </CardContent>
                </Card>
            </Box>

            <Box m="0 0 1em 1em">
                <Card>
                    <CardContent>
                        <Typography variant="h6" gutterBottom className={classes.fweight500}>Customer's KYC</Typography>
                        <Grid container className={classes.marginTop} style={{ marginBottom: '20px' }}>
                            <Grid item sm={2}>
                                <Box className={classes.paddingTop}>
                                    <AdjustIcon className={classes.iconColor} />
                                </Box>
                            </Grid>
                            <Grid item sm={8} style={{ marginTop: '5px', marginLeft: '-20px' }}>
                                <Box>
                                    <Typography className={classes.fontWeightSize}>Email verified</Typography>
                                    <InputLabel className={classes.fontWeightSizeSub2} variant="h6">Level 1 completed at {(props.record) ? props.record.email_verified_date : ''}</InputLabel>
                                </Box>
                            </Grid>
                            <Grid item sm={2}>
                                <Box>
                                    <div style={{ textAlign: 'right' }}>
                                        <Switch
                                            checked={props.record && props.record.email_verified}
                                            onColor="#86d3ff"
                                            onHandleColor="#2693e6"
                                            handleDiameter={18}
                                            uncheckedIcon={false}
                                            checkedIcon={false}
                                            boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                            activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                            height={15}
                                            width={30}
                                            className={classes.reactSwitch}
                                            id="material-switch-email"
                                        />
                                    </div>
                                </Box>
                            </Grid>
                        </Grid>

                        <Grid container className={classes.marginTop} style={{ marginBottom: '20px' }}>
                            <Grid item sm={2}>
                                <Box className={classes.paddingTop} mr="0.5em">
                                    <AdjustIcon className={classes.iconColor} />
                                </Box>
                            </Grid>
                            <Grid item sm={8} style={{ marginTop: '5px', marginLeft: '-20px' }}>
                                <Box>
                                    <Typography className={classes.fontWeightSize}>Personal verified</Typography>
                                    <InputLabel className={classes.fontWeightSizeSub2} variant="h6">Level 2 completed at {(props.record) ? props.record.phone_verified_date : ''}</InputLabel>
                                </Box>
                            </Grid>
                            <Grid item sm={2}>
                                <Box component="div" style={{ textAlign: 'right' }}>
                                    <Switch
                                        checked={(props.record) ? props.record.phone_verified : false}
                                        onColor="#86d3ff"
                                        onHandleColor="#2693e6"
                                        handleDiameter={18}
                                        uncheckedIcon={false}
                                        checkedIcon={false}
                                        boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                        activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                        height={15}
                                        width={30}
                                        className={classes.reactSwitch}
                                        id="material-switch-email"
                                    />
                                </Box>
                            </Grid>
                        </Grid>

                        <Grid container className={classes.marginTop}>
                            <Grid item sm={2}>
                                <Box className={classes.paddingTop} mr="0.5em">
                                    <AdjustIcon className={classes.iconColor} />
                                </Box>
                            </Grid>
                            <Grid item sm={8} style={{ marginTop: '5px', marginLeft: '-20px' }}>
                                <Box>
                                    <Typography className={classes.fontWeightSize}>Corporate verified</Typography>
                                    <InputLabel className={classes.fontWeightSizeSub2} variant="h6">Level 3 completed at {(props.record) ? props.record.document_verified_date : ''}</InputLabel>
                                </Box>
                            </Grid>
                            <Grid item sm={2}>
                                <Box component="div" style={{ textAlign: 'right' }}>
                                    <Switch
                                        checked={(props.record) ? props.record.document_verified : false}
                                        onColor="#86d3ff"
                                        onHandleColor="#2693e6"
                                        handleDiameter={18}
                                        uncheckedIcon={false}
                                        checkedIcon={false}
                                        boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                        activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                        height={15}
                                        width={30}
                                        className={classes.reactSwitch}
                                        id="material-switch-email"
                                    />
                                </Box>
                            </Grid>
                        </Grid>

                    </CardContent>
                </Card>
            </Box>
        </div>)
}
export default Aside;
